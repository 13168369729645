<template>
  <div class="h-full content-bg">
    <div :class="config.isDetailDisplay ? 'small-list' : 'scroll-auto'">
      <Control :config="config" @onChangeEditDataSource="onChangeEditDataSource" @shuju="shuju"></Control>
    </div>
   <div v-show="config.isDetailDisplay" class="detail">
      <EditControl v-if="showEditControl" :dataSource="editDataSource" :config="config" :Carriers="Carriers">
      </EditControl> 
    </div>
  </div>
</template>

<script>
// import Control from './components/list.vue';
// import EditControl from './components/edit.vue';
export default {
  data() {
    return {
      Carriers: {},
      showEditControl: false,
      config: {
        isDetailDisplay: false,
      },
      editDataSource: {},
      initialDataSource: {}
    }
  },
  mounted() {
    this.Utils.lazy(() => {
      this.showEditControl = true;
    }, 500);
  },
  methods: {
    shuju: function (data) {
      this.Carriers = data.Result;
    },
    onChangeEditDataSource: function (dataSource) {
      //dataSource.DispatchAppEnabled = dataSource.DispatchAppEnabled ? "1" : "2";
      //dataSource.DesensitizationEnabled=dataSource.DesensitizationEnabled ? "1" : "2";
      this.editDataSource = dataSource;

      this.initialDataSource = this.Utils.extend({}, dataSource);
    },
    syncDataSource() {
      this.initialDataSource = this.Utils.extend({}, this.editDataSource);
    },
    checkSaveStatus() {
      this.config.isDetailDisplay = false;
    }
  },
  components: {
    "EditControl": resolve => { require(['./components/editToPerm.vue'], resolve) },
    "Control": resolve => { require(['./components/list.vue'], resolve) },
  }
}
</script>